<template>
  <div style="height: inherit">

    <!-- List Header -->
    <section id="ecommerce-header">
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        style="cursor:pointer;"
        @click="back"
      >
        Back
      </b-button><br><br> -->
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <!--
                <feather-icon
                  icon="MenuIcon"
                  class="d-block d-lg-none"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
                <div class="search-results">
                  {{ totalProjects }} results found
                </div>
              -->
            </div>
            <div class="view-options d-flex">
              <b-form-checkbox
                id="show-inactives"
                v-model="showInactives"
                name="checkbox-1"
                @change="onCheckShowInactive"
              >
                Show used's
              </b-form-checkbox>
              <!-- Sort Button -->
              <b-dropdown
                v-show="false"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />
    <div
      v-if="isLoading"
      class="search-results text-center"
    >
      <br><br>
      <b-spinner
        variant="primary"
        label="Loading data from the Blockchain..."
        small
      />
      Fetching data from the Blockchain... Please wait a moment while we retrieve the data from the Blockchain..
    </div>
    <div
      v-if="genetics.length == 0 && !isLoading"
      class="search-results text-center"
    >
      <br><br>
      No results found, try changing Blockchain network to find your assets...
    </div>
    <!-- Prodcuts -->
    <section
      v-if="!isLoading"
      :class="itemView"
    >
      <b-card
        v-for="genetic in genetics"
        :key="genetic.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-img
            :src="genetic.image"
            class="w-100"
          />
        </div>

        <!-- genetic Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link class="text-body">
              <span style="color:#3a632e; font-size:16px">{{ genetic.name }}</span>
            </b-link>
          </h6>
          <b-badge
            class="profile-badge mb-1"
            variant="light-primary"
          >
            Token ID: <feather-icon icon="HashIcon" />{{ genetic.tokenId }} <span v-show="genetic.generation">|| Gen: {{ genetic.generation }}</span>
          </b-badge>
          <b-card-text class="item-description mb-1">
            {{ genetic.description }}
          </b-card-text>
          <b-badge
            class="profile-badge mb-1"
            variant="light-primary"
          >
            Uses Available: {{ genetic.useds }} <span v-show="genetic.generation">|| Gen: {{ genetic.generation }}</span>
          </b-badge>
          <b-badge
            class="item-description mb-1"
            variant="light-primary"
          >
            Attributes
          </b-badge>
          <b-card-text
            v-for="attribute in genetic.attributes"
            :key="attribute.trait_type"
            style="margin:0"
            class="item-description"
          >
            <ul
              class="product-features list-unstyled"
              style="margin:0"
            >
              <li>
                <feather-icon
                  v-if="attribute.trait_type=='Backgrounds'"
                  icon="SquareIcon"
                />
                <feather-icon
                  v-if="attribute.trait_type=='Auras'"
                  icon="FramerIcon"
                />
                <feather-icon
                  v-if="attribute.trait_type=='Bodies'"
                  icon="UserIcon"
                />
                <feather-icon
                  v-if="attribute.trait_type=='Glasses'"
                  icon="EyeIcon"
                />
                &nbsp;
                <span>{{ attribute.value }}</span>
              </li>
            </ul>
          </b-card-text>
        </b-card-body>

        <!-- genetic Actions -->
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="showHashBlockExplorer(genetic.tokenId, genetic.contract)"
          >
            <feather-icon
              icon="LayersIcon"
              class="mr-50"
            />
            <span>View on Blockchain</span>
          </b-button>
          <b-button
            v-if="!genetic.isUsed"
            variant="secundary"
            class="mt-1 btn-cart"
            style="border-color: #3a632e;"
            @click="pay(genetic.tokenId, genetic.productType, genetic.greenHouseId, genetic.ownerStore, genetic.isDelivery)"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span>
              {{ genetic.productType==0?'Make an Appointment':'Claim or Use it' }}
              <br>
              <span ref="timeleft" />
            </span>
          </b-button>
          <b-button
            v-else
            variant="secundary"
            class="mt-1 btn-cart"
            style="border-color: #3a632e;"
            @click="receiveDelivery(genetic.tokenId, genetic.greenHouseId)"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span>
              Mark as received
              <br>
              <span ref="timeleft" />
            </span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section v-if="!isLoading">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProjects"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>

    <!-- Modal para scanear y pagar -->
    <b-modal
      ref="modalUsesPayNftAmount"
      centered
      modal-header-close="true"
      ok-title="Use o make Delivery"
      cancel-title="Scan QR Now"
      title="Get your product delivered to your home or select the quantity you wish to claim."
      @ok="payDelivery"
      @cancel="playQRModal"
    >
      <b-col>
        <validation-observer ref="payNftSUseAmountSetup">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <h5>Uses Amount</h5>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="productuses"
                  >
                    <b-form-input
                      v-model="productUses"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Product Uses"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-modal>

    <!-- Modal para definir si usa delivery o OR -->
    <!-- <b-modal
      ref="modalUsesPayNft"
      centered
      cancel-title="Scan QR"
      ok-title="Delivery"
      title="Assigne use amount"
      @ok="payModal"
    >
      <b-col>
        <validation-observer ref="payNftSUseAmountSetup">
          <b-form>
            <b-row>
              <b-col md="12">
                <span>Please select the pay option</span>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-modal> -->

    <!-- Modal para scanear y pagar en cuanto se detecte el QR -->
    <b-modal
      ref="modalUsesPayNftQR"
      centered
      no-close-on-backdrop
      title="Scan QR Store"
    >
      <b-col>
        <validation-observer ref="payNftSUseAmountSetup">
          <b-form>
            <b-row>
              <b-col md="12">
                <div style="width:300px">
                  <StreamBarcodeReader
                    @decode="onDetect"
                    @error="onError"
                  />
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-modal>

    <b-modal
      ref="modalCalendar"
      hide-footer
      size="lg"
      centered
      title="Select the day of your appointment"
    >
      <b-col>
        <b-row>
          <b-col>
            <Calendar
              ref="calendarRef"
              :finish="okCalendarModal"
            />
          </b-col>
          <!-- <b-col>
            <b-calendar v-model="calendarDay" />
          </b-col>
          <b-col>
            <b-form-timepicker v-model="calendarTime" />
          </b-col> -->
        </b-row>
      </b-col>
    </b-modal>
    <b-modal
      ref="modalLoading"
      centered
      ok-only
      hide-footer
      ok-disabled
      ok-title="Accept"
      no-close-on-backdrop
      title="Processing..."
    >
      <b-col>
        <span>Processing the payment, please wait a moment</span>
      </b-col>
    </b-modal>

  </div>
</template>

<script>
import {
  BDropdown, BFormCheckbox, BForm, BFormGroup, BFormInput, BBadge, BModal, BSpinner, BImg, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BCard, BCardBody, BLink, BCardText, BButton, BPagination,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* eslint-disable import/no-extraneous-dependencies */
import { StreamBarcodeReader } from 'vue-barcode-reader'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ListLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useList'
// eslint-disable-next-line import/no-cycle
import { useEcommerceUi } from '../useList'
import Calendar from '../../apps/calendar/Calendar.vue'

const sessionData = require('../../../libs/sessionData')

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BLink,
    BCardText,
    BButton,
    BPagination,
    BImg,
    BSpinner,
    BBadge,
    BModal,
    BFormCheckbox,
    // BCalendar,
    // BFormTimepicker,
    BFormInput,
    BForm,
    // SFC
    ShopLeftFilterSidebar,
    // Qalendar,
    ValidationProvider,
    ValidationObserver,
    Calendar,
    StreamBarcodeReader,
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions, showInactives,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProjects, isLoading, dataInCache,
    } = useShopUi()

    const { genetics, fetchGenetics, nftUseds } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const Session = sessionData.default
    const dataSession = new Session().get()
    isLoading.value = true
    // Wrapper Function for `fetchGenetics` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchGenetics({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
        address: (dataSession.data.invitation.wallet) ? dataSession.data.invitation.wallet : dataSession.data.kyc.wallet,
        swInact: showInactives.value,
        cacheData: dataInCache.value,
      })
        .then(response => {
          isLoading.value = false
          genetics.value = response.data.genetics
          totalProjects.value = response.data.total
          nftUseds.value = response.data.usedNfts
          dataInCache.value = response.data.filteredData
        })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      showInactives,
      // useShopUi
      itemView,
      itemViewOptions,
      totalProjects,
      toggleProductInWishlist,
      handleCartActionClick,
      isLoading,

      // useShopRemoteData
      genetics,
      nftUseds,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
      dataSession,
      fetchShopProducts,
      dataInCache,
    }
  },
  data() {
    this.usrData = this.$sessionData.get()
    return {
      required,
      blockchainId: null,
      isZero: this.usrData.isZero,
      productUses: 1,
      currentTokenId: 0,
      currentType: 0,
      currentGreenHouseId: 0,
      currentDateAppointment: '',
      // format: 'week',
      // startDate: '2020-05-03',
      // calendarDay: '',
      calendarTime: '',
    }
  },
  created() {
    this.blockchainId = this.$sessionData.getCurrentNetworkData()
  },
  methods: {
    onCheckShowInactive() {
      this.isLoading = true
      if (this.showInactives) { // si se esta activando, es porque estaba inactivo, hacemos que se recargue el listado
        this.dataInCache = null
      }
      this.fetchShopProducts()
    },
    onDetect (detectedCodes) {
      // preguntar si desea continuar y hacer la TX con el address escaneado
      if (this.currentOwnerStore.toLowerCase() !== detectedCodes.toLowerCase()) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'QR Code invalid.',
            variant: 'warning',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
      } else { // si se verificó la tienda entonces pagamos
        this.payQR()
      }
    },
    onError(err) {
      // eslint-disable-next-line no-console
      console.log(err)
    },
    calendarSelectHandle(e) {
      // eslint-disable-next-line no-console
      console.log(e)
    },
    timesSelected(e) {
      // eslint-disable-next-line no-console
      console.log(e)
    },
    eventClicked(e) {
      // eslint-disable-next-line no-console
      console.log(e)
    },
    showHashBlockExplorer(tokenId, contractAddress) {
      if (tokenId === '') return
      const contract = contractAddress
      switch (parseFloat(this.blockchainId.id)) {
        case 1:
          window.open(`https://etherscan.io/token/${contract}?a=${tokenId}#inventory`, '_blank')
          break
        case 97:
          window.open(`https://testnet.bscscan.com/token/${contract}?a=${tokenId}#inventory`, '_blank')
          break
        case 56:
          window.open(`https://bscscan.com/token/${contract}?a=${tokenId}#inventory`, '_blank')
          break
        case 137:
          window.open(`https://polygonscan.com/token/${contract}?a=${tokenId}#inventory`, '_blank')
          break
        case 42220:
          window.open(`https://celoscan.io/token/${contract}?a=${tokenId}#inventory`, '_blank')
          break
        default:
          break
      }
    },
    // showModalNew() {
    //   this.$refs.modalUsesPayNft.show()
    // },
    // closeModalNew() {
    //   this.$refs.modalUsesPayNft.hide()
    // },
    showModalCalendar() {
      this.$refs.modalCalendar.show()
      setTimeout(() => {
        const calendar = this.$refs.calendarRef.$refs.refCalendar.getApi()
        calendar.changeView('dayGridMonth')
      }, 1000)
    },
    closeModalCalendar() {
      this.$refs.modalCalendar.hide()
    },
    showModalAmount() {
      this.$refs.modalUsesPayNftAmount.show()
    },
    closeModalAmount() {
      this.$refs.modalUsesPayNftAmount.hide()
    },
    showModalQR() {
      this.$refs.modalUsesPayNftQR.show()
    },
    closeModalQR() {
      this.$refs.modalUsesPayNftQR.hide()
    },
    showLoading() {
      this.$refs.modalLoading.show()
    },
    closeLoading() {
      this.$refs.modalLoading.hide()
      // this.toHome()
    },
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    pay(_tokenId, type, greenHouseId, ownerStore, isDelivery) {
      this.currentTokenId = _tokenId
      this.currentType = type
      this.currentGreenHouseId = greenHouseId
      this.currentOwnerStore = ownerStore
      this.currentisDelivery = isDelivery
      switch (type) {
        case 0:
          this.showModalCalendar()
          break
        default:
          this.showModalAmount()
          break
      }
      setTimeout(() => {
        if (document.getElementsByClassName('close')[0]) {
          document.getElementsByClassName('close')[0].style.display = 'block'
        }
      }, 1000)
    },
    // pagar por envio x cantidad
    payDelivery() {
      if (!this.currentisDelivery) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'This product dont support delivery service',
            variant: 'warning',
          },
        },
        {
          position: 'bottom-center',
          timeout: 3000,
        })
        return
      }
      this.closeModalAmount()
      this.payWithNftUse()
    },
    receiveDelivery(tokenId, greenHouseId) {
      this.showLoading()
      // eslint-disable-next-line no-underscore-dangle
      this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
      this.$http.post(`/greenhouse/${greenHouseId}/acceptdelivery?nid=${this.$sessionData.getNetwork()}`, {
        tokenid: tokenId,
        wallet: this.$sessionData.getUsrWallet(),
      }).then(async () => {
        this.closeLoading()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Your product has been confirmed successfully, thanks for use out services.',
            variant: 'success',
          },
        },
        {
          position: 'bottom-center',
          timeout: 15000,
        })
        this.$router.push({ name: '/' })
          .then(() => {
            this.$router.push({ name: 'my-genetic' }).catch(() => {})
          })
          .catch(() => {})
      })
        .catch(e => {
          if (e.status === 401) {
            this.logout()
          }
          // eslint-disable-next-line no-console
          console.log(e)
          this.closeLoading()
          this.cleanCurrentData()
          this.closeLoading()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: 'Could not confirmed the product, please try again',
              variant: 'warning',
            },
          },
          {
            position: 'bottom-center',
            timeout: 3000,
          })
        })
    },
    // ultimo paso con el QR
    payQR() {
      this.closeModalQR()
      this.payWithNftUse()
    },
    // Abrir modal QR
    playQRModal() {
      this.closeModalAmount()
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(res => {
          if (res.active) {
            this.showModalQR()
          }
        })
    },
    okCalendarModal(event) {
      // if (this.calendarTime === '') return
      // eslint-disable-next-line no-console
      console.log(event)
      const endDate = new Date(event.start)
      endDate.setMinutes(endDate.getMinutes() + 30)
      this.currentDateAppointment = `${event.start}T${endDate}`
      this.closeModalCalendar()
      this.payWithNftUse()
    },

    cleanCurrentData() {
      this.currentTokenId = 0
      this.currentType = 0
      this.productUses = 1
      this.currentGreenHouseId = 0
      this.currentOwnerStore = ''
      this.currentisDelivery = false
    },
    /**
     * type es el index de la tienda en BCA web2, para identificar el tipo de producto a adquirir, cada NFT tiene este dato
     */
    payWithNftUse() {
      this.showLoading()
      // eslint-disable-next-line no-underscore-dangle
      const _tokenId = this.currentTokenId
      // eslint-disable-next-line no-underscore-dangle
      this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
      this.$http.post(`/greenhouse/${this.currentGreenHouseId}/genetic/pay?nid=${this.$sessionData.getNetwork()}`, {
        tokenId: _tokenId,
        owner: this.currentOwnerStore,
        amount: this.productUses,
        dateTime: this.currentDateAppointment,
        wallet: this.$sessionData.getUsrWallet(),
      }).then(async () => {
        this.cleanCurrentData()
        // this.fetchGenetics()
        this.genetics.filter(nft => nft.tokenId === _tokenId)[0].amount -= this.productUses
        this.closeLoading()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Your product has been used successfully.',
            variant: 'success',
          },
        },
        {
          position: 'bottom-center',
          timeout: 15000,
        })
        this.$router.push({ name: '/' })
          .then(() => {
            this.$router.push({ name: 'my-genetic' }).catch(() => {})
          })
          .catch(() => {})
      })
        .catch(e => {
          if (e.status === 401) {
            this.logout()
          }
          // eslint-disable-next-line no-console
          console.log(e)
          this.closeLoading()
          this.cleanCurrentData()
          this.closeLoading()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: 'Could not used the product, please try again',
              variant: 'warning',
            },
          },
          {
            position: 'bottom-center',
            timeout: 3000,
          })
        })
    },
    back() {
      this.$router.push({ name: '/', params: { iz: this.iz } })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
  .modal-header .close {
    display:block;
  }
}
</style>
